import React from 'react'
import Page from './Page'
import { repair_img_url } from '../../assets'

export default function Repair() {


  return (
    <div><Page kind={"repair services"} img_url={repair_img_url}/></div>
  )
}
