import React from 'react'
import Page from './Page'
import { engineering_img_url } from '../../assets'

export default function Engineering() {


  return (
    <div><Page kind={"engineering"} img_url={engineering_img_url}/></div>
  )
}
